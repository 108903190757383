@import "../../shared/sass/variables.scss";

.is-content-request-section {
  overflow: hidden !important
  ;
}

.content-request-main-container {
  max-width: calc(100vw - 65px);
  overflow: hidden;
  #dataGrid {
    margin: 0 auto;
  }

  .banner {
    min-width: calc(100vw - 65px);
    max-width: calc(100vw - 65px);
    min-height: 15vh;
    max-height: 15vh;
    margin: 5em 0 0 0;
    .banner-overlaySC {
      height: 100%;
      background: url("https://cdn.bemaster.com/media/images/admin/backgrounds/BannerPAD_solicitud_de_contenido.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .banner-img {
        display: grid;
        place-items: center;
        min-height: 15vh;
        max-height: 15vh;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        h1 {
          font-size: 45px;
          filter: drop-shadow(2px 4px 6px #000);
        }
      }
    }
  }

  .chargingPage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
    img {
      max-width: 100px;
    }
  }

  #descriptionArea {
    width: 100%;
    height: 190px;
    max-height: 190px;
    resize: none;
  }

  .containerRequest {
    min-height: 100%;
    .fecha {
      display: block;
      margin: auto;
      text-align: center;
      h5 {
        margin: 25px auto;
        letter-spacing: 2px;
      }
      #datePrincipio {
        margin: auto;
      }
    }
    .equipo {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 20px;
      h5 {
        margin: 10px;
        letter-spacing: 2px;
      }
    }
  }
}
 
#charging {
  max-width: 40px;
  position: absolute;
  bottom: 25px;
  left: calc(50% - 20px);
}