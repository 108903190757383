@import '../sass/variables.scss';

@mixin icon($url) {
    background-image: url($url);
    background-repeat: no-repeat;
    height: $icon-heigth;
    width: $icon-width;
}

//SIDEBAR ICONS

.is-arrow-icon {
    background-image: url('https://cdn.bemaster.com/media/icons/home/Sitiobemaster34.svg');
    background-size: cover;
    height: 30px;
    transform: rotate(-180deg);
    transition: 0.25s linear all;
    width: 20px;
}

.is-profile-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster53.svg');
}

.is-support-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster42.svg');
}

.is-career-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster41.svg');
}

.is-shop-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster40.svg');
}

.is-mentortalks-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster39.svg');
}

.is-community-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster38.svg');
}

.is-classroom-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster36.svg');
}

.is-powerclass-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster37.svg');
}

.is-home-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster35.svg');
}


.is-settings-icon {
    background-image: url('https://cdn.bemaster.com/media/icons/home/Sitiobemaster55.svg');
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 30px;
    width: 30px;
}

.is-notification-icon {
    @include icon('https://cdn.bemaster.com/media/icons/home/Sitiobemaster54.svg');
    cursor: pointer;
}

.is-brand-icon {
    background-image: url('https://cdn.bemaster.com/media/images/login/Logo-BeMaster.png');
    background-attachment: inherit;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.5s linear all;
    height: 57px;
    width: 170px;
}

//TRIUMPHS

.triumph-class {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: $triumph-heigth;
    width: $triumph-width;
}

.is-reward-icon {
    background-size: cover;
    background-repeat: no-repeat;
    height: $icon-reward-heigth;
    width: $icon-reward-width;
}

// Powerclasses

.powerclass-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    @media (max-width: $mobile) {
        background-position: unset;
    }
}
