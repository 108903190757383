.funnels_body{     
    min-height: 20vh;
    max-height: 25vh;
    width: 80%;
    margin: 1rem ;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 2px;
     &:hover{
        border: 2px solid goldenrod;
        cursor:pointer;
     }

    p{
        font-size: 1.2rem;
        margin: 1px;
    }
    .funnels_title{
        font-size: 2rem;
        color: goldenrod;
        margin-top: 4px;
    }
}
