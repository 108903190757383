@import "../../shared/sass/variables.scss";

.sales-affiliates-main-container {
  a:hover {
    color: white !important;
  }
  min-height: 100vh;
  .containerTarjetas {
    min-height: 150px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .tarjetas {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .tarjetaInfo {
        user-select: none;
        width: 250px;
        height: 80px;
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        color: #c19656;
        filter: drop-shadow(-2px 2px 16px #000);
        .tarjetaTittle {
          font-size: 15px;
          color: #555;
        }
        .tarjetaValue {
          font-size: 20px;
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }
}
