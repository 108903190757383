
.escalation-mastertools-main-container{
    margin: auto;
    text-align: center;
}
.search{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 2rem;
    font-size: 0.8rem;
    font-family: sans-serif;
}
.searchLabel{
    margin: 0 0.5rem;
    font-weight: bold;
}

.searchInput{
    padding: 2px; 
    text-align: center;
    background-color: rgb(70,70,70);
    color: #fff;
    border: 1px solid rgb(50,50,50);
    border-radius: 2%;
}

.searchInput:hover{
    background-color: rgb(200,200,200);
    color: black;
    cursor: pointer
}

.searchInput:focus{
    background-color: rgb(200,200,200);
    color: black;
}