.main-user-request-container {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: calc(100vw - 65px);
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .banner {
    min-width: calc(100vw - 65px);
    max-width: calc(100vw - 65px);
    min-height: 10vh;
    max-height: 10vh;
    .banner-overlay {
      height: 100%;
      background: url("https://cdn.bemaster.com/media/images/admin/backgrounds/BannerPAD_solicitud_de_permisos.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .banner-img {
        display: grid;
        place-items: center;
        min-height: 10vh;
        max-height: 10vh;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        h1 {
          font-size: 40px;
          filter: drop-shadow(2px 4px 6px #000);
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .main-user-request-container {
    max-width: calc(100vw - 25px);
    .banner {
      margin: 1vh;
      min-width: calc(100vw - 25px);
      max-width: calc(100vw - 25px);
      .banner-overlay {
        .banner-img {
          h1 {
            font-size: 25px;
            filter: drop-shadow(2px 4px 6px #000);
          }
        }
      }
    }
  }
}
