@import '../../../components/notification-container/notification-container.styles.scss';
@import '../../../shared/sass/variables.scss';

@mixin center {
    display: flex;
    justify-content: center;
 }

.settings-container {
    @include navbar-basic-container(-10px, 155px, 95px);
    padding: 2px 15px;
    @media (max-width: $mobile) {
        margin-right: 10px;
    }
    .links-section {
        .account-link {
            border-bottom: solid .7px rgba(179, 179, 179, 0.685);
            padding-bottom: 1px;
            span {
                cursor: pointer;
            }
        }
        .profile-link {
            cursor: pointer;
        }
        .help-link {
            cursor: pointer;
        }
        .logout-link {
            cursor: pointer;
        }
    }
}