@import '../../shared/sass/variables.scss';

.leads-to-call-main-container {
    a:hover {
        color: white !important;
    }
}
.tabits{
    justify-content: space-between !important;
    align-items: center !important;
    color: red !important;
}