.notification-bm-management-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  > .container-button {
    > a {
      border-bottom: 1px solid #c29558;
      border-left: 1px solid #c29558;
      border-radius: 5px;
      box-shadow: 1px -1px 4px #fff;
      font-size: 15px;
      padding: 15px;
      transition: all ease-in-out 0.5s;
      &:hover {
        background-color: #c29558;
        border: 0;
        box-shadow: none;
      }
    }
  }
}
.containerForm {
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  > .rowForm {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    padding: 10px;
    > .itemForm {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      margin-left: 50px;
      > span {
        letter-spacing: 1px;
      }
      > input {
        color: white;
        max-width: calc(100% - 20px);
        outline: none;
        background-color: transparent;
        border: none;
        border-bottom: solid 1px #888;
        transition: ease-in-out 0.2s;
        padding: 10px 0;
        &:hover {
          border-bottom: solid 1px white;
        }
        &:focus {
          border-bottom: solid 1px goldenrod;
        }
      }
      > textarea {
        background-color: transparent;
        border: none;
        border-bottom: solid 1px #888;
        color: white;
        height: 100px !important;
        max-width: calc(100% - 50px);
        outline: none;
        padding: 10px 0;
        resize: none;
        transition: ease-in-out 0.2s;
        &:hover {
          border-bottom: solid 1px white;
        }
        &:focus {
          border-bottom: solid 1px goldenrod;
        }
      }
    }
  }
}
