@import '../../../components/notification/notification.styles.scss';

.parent-settings-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .child-settings-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        >.is-notification-icon {
            cursor: pointer;
        }
    }

    >.description-content {
        display: flex;
        justify-content: flex-end;
        .is-a-icon {
            @include custom-arrow(16px);
        }
    }
}
