.main-Affiliates-management-container {
  padding: 0;
  margin: 0 2rem 0 0;
  box-sizing: border-box;
  max-width: calc(100vw - 65px);
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .banner {
    min-width: calc(100vw - 65px);
    max-width: calc(100vw - 65px);
    min-height: 60px;
    max-height: 60px;
    margin: 3em 0 0 0;
    .banner-img {
      height: 60px;
      background: #000;
      background: url("https://cdn.bemaster.com/media/images/admin/backgrounds/BannerPAD_gestion_de_usuarios.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .banner-overlay {
        display: grid;
        place-items: center;
        min-height: 60px;
        max-height: 60px;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        h1 {
          font-size: 30px;
          filter: drop-shadow(2px 4px 6px #000);
        }
      }
    }
  }

  > .Search {
    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .searchForm{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > input[type="text"] {
        color: #fff;
        background-color: transparent;
        outline: none;
        border: none;
        border-bottom: solid 2px #888;
        font-size: 1.2rem;
        padding: 5px 5px;
        transition: ease-in-out 0.2s;
        text-align: center;
        &:hover {
          border-bottom: solid 2px white;
        }
        &:focus {
          border-bottom: solid 2px goldenrod;
        }
      }
      > .searchLabel{
        padding: 5px 15px 0 0;
        color: rgb(200,200,200);
        font-size: 1.1rem;
      }
      > .searchButton {
        align-items: flex-end;
        width: 50px;
        &:active{
          >.search-icon{
            transform: scale(0.8);
          }
        }
        > .search-icon {
          font-size: 35px;
          transition: all 0.2s;
          &:hover {
            cursor: pointer;
            filter: drop-shadow(0 0 1px #fff);
          }
        }
      }
    }
    > .paginado{
      display: flex;
      justify-content: flex-end;
      padding-top: 5px;
      span{
        font-size: 1.2rem;
        margin: 0 1rem;
      }
      Button{
        width: 5rem;
        padding: 0.3rem 0; 
      }
    }
  }
}

@media (max-width: 576px) {
  .main-Affiliates-management-container {
    max-width: calc(100vw - 25px);
    .banner {
      margin: 4vh;
      min-width: calc(100vw - 25px);
      max-width: calc(100vw - 25px);
      .banner-img {
        .banner-overlay {
          h1 {
            font-size: 30px;
            filter: drop-shadow(2px 4px 6px #000);
          }
        }
      }
    }

    > .search {
      justify-content: center;
      padding: 1vh;
    }
  }
}