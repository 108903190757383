@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

.has-background-image-home {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url("https://cdn.bemaster.com/media/images/admin/backgrounds/Banner PAD - Home - 2021-12-15.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 576px) {
  .has-background-image-home {
    background-position: 68%;
  }
}

.support-layer {
  background-color: white !important;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.has-custom-background {
  @media (max-width: $mobile) {
    height: -webkit-fill-available;
  }
  > .hero {
    @media (max-width: $mobile) {
      height: -webkit-fill-available;
    }
  }
}

.is-home-container {
  @media (max-width: $mobile) {
    flex-grow: 1;
  }
  > .community-custom-class {
    @media (max-width: $mobile) {
      align-items: flex-start;
      height: 100% !important;
      justify-content: center;
      .community-container {
        margin: 0 !important;
        .columns {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
      .ScrollbarsCustom-Content {
        padding-left: 15px !important;
      }
    }
  }
  > .is-content-section {
    display: flex;
    padding-left: 65px !important;
    @media (max-width: $mobile) {
      padding: 0 0 0 10px;
    }
    @media (min-width: $mobile) {
      align-items: flex-start;
    }
  }
  > .is-affiliation-request-section {
    display: flex;
    height: 100vh;
    padding: 0 0 0 8%;
    @media (max-width: $mobile) {
      padding: 0 0 0 10px;
    }
    @media (min-width: $mobile) {
      align-items: flex-start;
    }
  }
  > .is-certification-request-section {
    display: flex;
    height: 100vh;
    padding: 0 0 0 8%;
    @media (max-width: $mobile) {
      padding: 0 0 0 10px;
    }
    @media (min-width: $mobile) {
      align-items: flex-start;
    }
  }
  > .is-pclass-content-section {
    @media (max-width: $mobile) {
      align-items: flex-start;
    }
  }
  > .is-mentor-content-section {
    @media (max-width: $mobile) {
      align-items: flex-start;
      display: flex;
      padding: 0 !important;
    }
  }
  > .is-profile-content-section {
    @media (max-width: $mobile) {
      height: auto !important;
      padding: 0 !important;
      width: 100%;
    }
  }
  > .is-support-content-section {
    @media (max-width: $mobile) {
      width: 96% !important;
    }
  }
  > .is-fire-store-content-section {
    @media (max-width: $mobile) {
      margin: 0px !important;
      overflow-y: scroll !important;
      padding: 0 !important;
      width: 96% !important;
    }
  }
  .is-class-content-section {
    padding: 0 0 0 65px;
    height: 100vh;
    width: -webkit-fill-available;
    @media (max-width: $mobile) {
      padding: 0;
    }
  }
  .is-user-management-section {
    padding: 0 0 0 65px !important;
    height: 100vh;
    width: -webkit-fill-available;
    @media (max-width: $mobile) {
      padding: 0 0 0 25px !important;
    }
  }
  .is-clients-management-section {
    padding: 0 0 0 65px !important;
    height: 100vh;
    width: -webkit-fill-available;
    @media (max-width: $mobile) {
      padding: 0 0 0 25px !important;
    }
  }
}

.has-down-info {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}
