
// Brand colors
$red: #b73f39;
$black: #161616;
$brown: #c19656;
$gray-lighter: #f2f2f2;
// $grey-lighter: #e2e2e2;
$gray-darkness: #212121;
$green-petroleum: #2f5f55;
$dark-gray: #5e5e5e;    
$gray-progress: #3a3a3a;

// Others
$blue: 	#293e53;
$form-border: #909090da;
$support-input: #d6d6d6d5;
$green-toggle: #00a546;
$green-toggle-disabled: #00a54678;

//Dark Mode Variables
// $dark-background: #37474F;
$background-support-dark: $gray-darkness !important;
// $dark-background: $gray-dark-blue;
// $dark-container: $gray-darkness;
$gray-light-blue:#222c39;
$gray-dark-blue:#2e3442;
$blue:#29476b !important;
$gray-lighter:#dbdbdb;
$gray-lighter-secondary:#808080;

//Some custom variables
$main-window-background: #212121;
$conf-window-background: #f3f3f3;

// Fires
$fire-records-height: 40px;
$fire-records-width: 600px;

// Icon Variables
$icon-width: 30px;
$icon-heigth: 25px;

// Triumph Variables
$triumph-width: 65px;
$triumph-heigth: 65px;

// Reward Icon
$icon-reward-heigth: 40px;
$icon-reward-width: 40px;

// Junior Color
$junior: $red;

// Senior Color
$senior: $green-petroleum;

// Master Color
$master: $brown;

// Classroom Variables
$work-section-height: 75%;

// Update Bulma's global variables
$is-danger: $junior;

$family-sans-serif: "Gotham", sans-serif;
$grey-dark: $gray-darkness;
$gray-ligh: $gray-lighter;
$primary: $red;
$link: $black;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $grey-dark;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Responsive Dimentions
$mobile: 576px;