@import "../../shared/sass/variables.scss";

.container-rewards {
  margin: 1em auto 5em auto;
  width: 100%;
  overflow-y: auto;
  > .rewards-table-container {
    // background: url("https://images.pexels.com/photos/6532381/pexels-photo-6532381.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background: url("https://images.pexels.com/photos/6120403/pexels-photo-6120403.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    width: 100%;
    height: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: column wrap;
    > h1 {
      letter-spacing: 2px;
      font-size: 25px;
      font-weight: 700;
      margin: 1.5rem;
    }
    > .reward-table {
      border-radius: 10px;
      background: rgba($color: #000000, $alpha: 0.4);
      backdrop-filter: blur(4px);
      width: 550px;
      display: flex;
      justify-content: center;
      box-shadow: 2px 2px 16px #000;
      table {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        table-layout: fixed;
        tr {
          height: 25px;
          th {
            color: #fff !important;
            border: solid 1px $brown;
            padding: 5px 0;
          }
          td {
            border: solid 1px #c19656;
            padding: 5px 0;
          }
        }
      }
    }
  }
  .container-content {
    margin: 3em auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-flow: row wrap;
    .tittleGrid {
      font-family: $family-sans-serif;
      font-size: 25px;
      letter-spacing: 2px;
      text-align: center;
      margin: 15px;
      text-transform: uppercase;
      filter: drop-shadow(0px 0px 2px #c19656);
    }
    .containerGrid {
      max-width: 500px;
      overflow: hidden;
    }
    >.delivery-rewards{
      margin-top: 50px;
    }
  }
}
