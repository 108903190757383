.main-Affiliates-management-container {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: calc(100vw - 65px);
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .banner {
    min-width: calc(100vw - 65px);
    max-width: calc(100vw - 65px);
    min-height: 60px;
    max-height: 60px;
    margin: 3em 0 0 0;
    .banner-img {
      height: 60px;
      background: #000;
      background: url("https://cdn.bemaster.com/media/images/admin/backgrounds/BannerPAD_gestion_de_usuarios.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .banner-overlay {
        display: grid;
        place-items: center;
        min-height: 60px;
        max-height: 60px;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        h1 {
          font-size: 30px;
          filter: drop-shadow(2px 4px 6px #000);
        }
      }
    }
  }

  > .search {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2vh 2vw;
    > input[type="text"] {
      color: #fff;
      background-color: transparent;
      outline: none;
      border: none;
      border-bottom: solid 2px #888;
      font-size: 1.2rem;
      padding: 5px 5px;
      transition: ease-in-out 0.2s;
      text-align: center;
      &:hover {
        border-bottom: solid 2px white;
      }
      &:focus {
        border-bottom: solid 2px goldenrod;
      }
    }
    > .searchLabel{
      padding: 5px 15px 0 5px;
      color: rgb(200,200,200);
      font-size: 0.8rem;
    }
    > .searchButton {
      align-items: flex-end;
      display: flex;
      justify-content: center;
      width: 40px;
      &:active{
        >.search-icon{
          transform: scale(0.8);
        }
      }
      > .search-icon {
        font-size: 35px;
        transition: all 0.2s;
        &:hover {
          cursor: pointer;
          filter: drop-shadow(0 0 1px #fff);
        }
      }
    }
    > .buttonVolver {
      margin-right: 4vw;
    }
  }

}

@media (max-width: 576px) {
  .main-Affiliates-management-container {
    max-width: calc(100vw - 25px);
    .banner {
      margin: 4vh;
      min-width: calc(100vw - 25px);
      max-width: calc(100vw - 25px);
      .banner-img {
        .banner-overlay {
          h1 {
            font-size: 30px;
            filter: drop-shadow(2px 4px 6px #000);
          }
        }
      }
    }

    > .search {
      justify-content: center;
      padding: 1vh;
    }
  }
}