@import '../../shared/sass/variables.scss'; 

@mixin custom-arrow($mr:8px) {
    background-image: url('https://cdn.bemaster.com/media/icons/home/Sitiobemaster34.svg');
    background-size: cover;
    height: 25px;
    transform: rotate(90deg);
    margin-top: 5px;
    margin-right: $mr;
    width: 17px;
}

.parent-container {
    .is-notification-icon {
        display: flex;
        justify-content: flex-end;
    } 
    .child-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        >.icons-section {
            align-items: center;
            display: flex;
            svg {
                color: $red;
                cursor: pointer;
                height: 16px;
                margin-left: -17px;
            }
        }
        >.is-notification-icon {
            cursor: pointer;
            height: 28px !important ;
        }
    }
    .description-content {
        display: flex;
        justify-content: flex-end;
        .is-a-icon {
            @include custom-arrow();  
            z-index: 1;
        }
    }
}
