.modal{
    background-color: rgba(80, 80, 80, 0.7);
    color:#000;
}

select {
    background-color: #3a3a3a;
    color: #fff;
    padding: 0.3rem 0.5rem;
    border: none;
    border-bottom: 1px solid #a7a7a7;
    width: 100%;
    font-size: 1rem;
    
}
.my-swal {
    z-index: 50000 !important;
} 



