@import '../../../../shared/sass/variables.scss';

.is-noti-card {
    align-items: center;
    border-radius: 7px;
    background-color: #d4d4d4;
    cursor: pointer;
    min-height: 48px;
    margin: 10px 0;
    width: 225px;
    .buttons-section {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        min-height: 45px;
        margin-right: 5px !important;
        position: absolute;
        right: 13px;
        @media (max-width: $mobile) {
            right: 13% !important;
        }
        .close-icon { 
            font-size: 13px;
            cursor: pointer;
        }
        .notification-state {
            color: $red;
            cursor: pointer;
        }
        .fill-space {
            cursor: pointer;
        }
    }

    .is-noti-text{
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.5px;
        width: 85% !important;
    }
}

.is-noti-card:hover {
    background-color: #cacaca88;
}