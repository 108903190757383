.main-leads-management-container {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: calc(100vw - 65px);
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .banner {
    min-width: calc(100vw - 65px);
    max-width: calc(100vw - 65px);
    min-height: 10vh;
    max-height: 10vh;
    margin: 3vh 0 0 0;
    .banner-img {
      height: 100%;
      background: #000;
      background: url("https://cdn.bemaster.com/media/images/admin/backgrounds/BannerPAD_gestion_de_usuarios.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .banner-overlay {
        display: grid;
        place-items: center;
        min-height: 10vh;
        max-height: 10vh;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        h1 {
          font-size: 25px;
          filter: drop-shadow(2px 4px 6px #000);
        }
      }
    }
  }

  > .search {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2vh 7vw 0 0;
    > input[type="text"] {
      color: #fff;
      background-color: transparent;
      outline: none;
      border: none;
      border-bottom: solid 1px #888;
      padding: 5px 5px;
      transition: ease-in-out 0.2s;
      &:hover {
        border-bottom: solid 1px white;
      }
      &:focus {
        border-bottom: solid 1px goldenrod;
      }
    }
    > .searchButton {
      align-items: flex-end;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 30px;
      &:active {
        > .search-icon {
          transform: scale(0.8);
        }
      }
      > .search-icon {
        font-size: 20px;
        transition: all 0.2s;
        &:hover {
          cursor: pointer;
          filter: drop-shadow(0 0 1px #fff);
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .main-leads-management-container {
    max-width: calc(100vw - 25px);
    .banner {
      min-width: calc(100vw - 25px);
      max-width: calc(100vw - 25px);
      .banner-img {
        .banner-overlay {
          h1 {
            font-size: 25px;
          }
        }
      }
    }

    > .search {
      justify-content: center;
      padding: 1vh;
    }
  }
}
