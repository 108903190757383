
.user-panel {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-width: calc(100vw - 65px);
  max-width: calc(100vw - 65px);
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  margin:auto;

  .loading{
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
  }
  

.tittle{
  display: flex;
  justify-content: center;
  h1{
      font-size: 40px;
      filter: drop-shadow(2px 4px 6px #000);
      border-bottom: 2px solid #fff;
    }
}
    
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 10px 0 10px;
    > .search{
    > input[type="text"] {
      color: #fff;
      background-color: transparent;
      outline: none;
      border: none;
      border-bottom: solid 2px #888;
      font-size: 1.2rem;
      padding: 5px 5px;
      transition: ease-in-out 0.2s;
      text-align: center;
      &:hover {
        border-bottom: solid 2px white;
      }
      &:focus {
        border-bottom: solid 2px goldenrod;
      }
    }
    .searchLabel{
      padding: 5px 15px 0 5px;
      color: rgb(200,200,200);
      font-size: 0.8rem;
    }
    .searchButton {
      align-items: flex-end;
      display: flex;
      justify-content: center;
      width: 40px;
      font-size: 35px;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        filter: drop-shadow(0 0 1px #fff);
    }
      &:active{
        >.search-icon{
          transform: scale(0.8);
        }
      }
    }
    }
  }
  .body{
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 70vh;
    .userInfo{
      flex-grow: 1;
      border-right: 3px solid #fff;
    }
    .userTools{
      flex-grow: 4;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .noData{
    display: flex;
    width: 100%;
    justify-content: center;
  }
}