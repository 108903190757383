@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

@mixin elementHover($scale: 1.16, $delay: .4s, $hasBorder: true) {
    
    @if $hasBorder {
        border: 2px solid $brown;
    } 
    @else {
        transition: all ease-in-out 0.1s;
    }

    transform: scale($scale);
    transition-delay: $delay;
}

@mixin cardAnim($scale: 1.1) {
    border: 2px solid $brown;
    transform: scale($scale);
}

.card-container {
    align-items: center;
    background-size: cover;
    backface-visibility: hidden;
    border-radius: 22px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    -moz-osx-font-smoothing: grayscale;
    min-height: 170px;
    min-width: 111px;
    padding: 25px;
    transform: translateZ(0);
    transition: transform .2s ease-out;
    >.career-title{
        align-items: center;
        display: flex;
        justify-content: center;
        >.is-be-name{
            letter-spacing: 5px;
            font-weight: 300;
        }

        >.is-career-name{
            letter-spacing: 5px;
            font-weight: 500;
        }  
    }
}
  
.card-container:hover {
    @include cardAnim();
    @include boxShadow(19px, 20px, 23px, 3px);
}

