.userInfo_body{     
    height: 65vh;
    width: 90%;
    margin: 1rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    
    p{
        font-size: 1.2rem;
    }
    .userInfo_title{
        font-size: 1.6rem;
        color: goldenrod;
        margin-top: 4px;
    }

    #form{
        p{
            font-size: 0.9rem;
            margin-top: 1rem;
            margin-bottom: 0.1rem;
        }
        input{
            background-color: rgba(1,1,1,0);
            color: #fff;
            width: 80%;
            text-align: center;
            &:focus{
                border: 4px solid goldenrod;
            }
        }
        .error{
            color: rgb(248, 119, 119)
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

}
