@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

@mixin center {
  display: flex;
  justify-content: center;
}

.notification-container {
  @include navbar-basic-container();
  > .h1Notification {
    letter-spacing: 2px;
    font-size: 14px;
    filter: drop-shadow(1px 1px 2px #fff);
  }
  > .notifications-list {
    flex-direction: column;
    padding: 0 10px;
  }
  @media (max-width: $mobile) {
    left: 6% !important;
    // width: 60% !important;
    width: 82% !important;
  }
}
