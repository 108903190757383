.info-tab-container{
    width: 100%;
    height: 100%;
    background: #555;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
    >.image-info-tab-container{
        padding: 10px 0;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            height: 200px;
            border-radius: 50%;
        }
    }
    >.about-info-tab-container{
        text-align: center;
        width: 75%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        >p{
            width: 100%;
            white-space: pre-wrap;
        }
    }
    >.dataGrid-info-tab-container{
        width: 100%;
    }
}