.leads-refund-main-container{
    >h1{
        text-align: center;
        margin: 50px auto;
    }

    .datagrid-container{
        margin: auto;
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 100%;
    }
}