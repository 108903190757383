
//Purchase Modal
.power-purchase-container {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px; 
    >.exit-button-section {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        font-size: 25px;
        padding: 0px !important;
        position: absolute;
        right: 10px;
        top: 7px;
    }
    >.video-space-section {        
        background-position: center center;
        background-size: cover;
        border-radius: 20px;
        height: 250px;
        width: 100%;
    }
    .power-purchase-resume {
        >.purchase-details {
            h1 {
                font-weight: bold;
            }
            .price {
                margin-right:49%;
            }
           .price-mini-region {
               align-items: center;
               color: $brown;
               display: flex;
               font-weight: bold;
               justify-content: space-between;

               .p-details {
                    align-items: center;
                    display: flex;
                    justify-content: center;
               }
            }
        }

    }
}


//Reward Modal
