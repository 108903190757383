@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';
@import '../../components/card/card.styles.scss';

@mixin center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.is-login-sect {
    @include center;
    align-items: center;
    display: flex;
    justify-content: center;
    background: $main-window-background;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    height: 350px;
    margin: 0 25px;
    width: 390px;
    @media (max-width: $mobile) {
        padding: 13px;
        margin: auto;
        width: 90%;
        .is-title-section {
            p {
                font-size: 22px !important;
                text-align: center;
            }
        }    
    }
    >.is-title-section {
        margin: 0 !important;
    }
    .wrong-pass {
        color: $red;
    }
    .email-required {
        color: $red;
    }
}