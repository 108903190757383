@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

@mixin chart() {
  @include boxShadow(0px, 3px, 10px, 0px, 0.15);
  align-items: center;
  // background: $gray-dark-blue;
  background: rgb(25, 44, 44);
  display: flex;
  flex: 50%;
  justify-content: center;
  margin: 10px;
}

.main-dashboard-container :is(h1) {
  font-size: 28px !important;
}

.main-dashboard-container {
  overflow: auto !important;
  height: 100vh;
  padding: 1% 2%;
  width: 100%;
  div {
    border-radius: 5px;
  }

  .form {
    .dx-menu :is(span) {
      font-size: 18px !important;
    }
    .dx-menu {
      @include boxWhiteShadow(0px, 0px, 10px, 3px);
      align-items: center;
      background: rgb(25, 44, 44);
      border-radius: 5px;
      display: flex;
      height: 60px;
      justify-content: space-between;
      padding: 0 40px;
    }
    .dx-filters :is(span) {
      font-size: 18px !important;
    }
    .dx-filters {
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      display: flex;
      height: 60px;
      justify-content: space-between;
      padding: 0 40px;
      margin: 20px 0;
    }
  }

  .top-area-indicators {
    display: flex;
    .indicators {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      font-size: 30px;
      width: 50%;
      span {
        color: white;
        font-size: 12px;
      }
      .item {
        @include chart;
        display: flex;
        justify-content: space-between;
        min-height: 130px;
        padding: 0 15px;
        background: rgb(25, 44, 44);
        .description-indicator {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
        }

        &:hover {
          @include boxWhiteShadow(0px, 0px, 10px, 1px);
          transition: all ease-in-out 0.32s;
        }
      }
    }
    .table {
      display: grid;
      grid-template-columns: 1fr;
      margin: 10px 0;
      width: 50%;
      #grid {
        transition: all ease-in-out 0.32s;
        background: rgb(25, 44, 44);
        .tittle {
          text-align: center;
          color: #fff;
          background: #3a3a3a;
          font-family: $family-sans-serif;
          border: solid 0.1px #555;
          letter-spacing: 1px;
        }
        &:hover {
          @include boxWhiteShadow(0px, 0px, 10px, 1px);
        }
        #tableGrid {
          max-height: 250px;
          td[role="columnheader"] {
            text-align: center !important;
          }
        }
      }
      .imgCell {
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
      .first {
        background-image: url("https://cdn.bemaster.com/media/images/admin/gifs/Fuego.gif");
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center;
        padding: 10px;
        display: flex;
        justify-content: center;
        .position1 {
          font-family: $family-sans-serif;
          width: 100px;
          padding: 5px;
          font-size: 1rem;
        }
      }
      .position {
        font-family: $family-sans-serif;
        width: 100px;
        padding: 5px;
        font-size: 1rem;
        border-bottom: solid 2px #888;
      }
    }
  }

  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .item {
      @include chart;
      transition: all ease-in-out 0.32s;
      min-height: 300px;
    }
    .item:hover {
      @include boxWhiteShadow(0px, 0px, 10px, 1px);
    }
  }

  .large-item:hover {
    @include boxWhiteShadow(0px, 0px, 10px, 1px);
    transition: all ease-in-out 0.32s;
  }
  .large-item {
    @include boxShadow(0px, 3px, 10px, 0px, 0.15);
    align-items: center;
    background: rgb(25, 44, 44);
    display: flex;
    justify-content: center;
    div {
      width: 90%;
    }
  }

  @media (max-width: $mobile) {
    .form {
      .dx-menu :is(span) {
        font-size: 15px !important;
      }
      .dx-menu {
        flex-flow: row wrap;
        height: 80px;
      }
      .dx-filters :is(span) {
        font-size: 15px !important;
      }
      .dx-filters {
        flex-flow: column wrap;
        height: 190px;
        padding: 20px 40px;

        .dates_container {
          height: 50px;
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .top-area-indicators {
      display: flex;
      flex-flow: column wrap;
      .indicators {
        grid-template-columns: repeat(1, 1fr);
        font-size: 24px;
        width: 100%;
        span {
          color: white;
          font-size: 12px;
        }
        .item {
          justify-content: space-evenly;
          min-height: 110px;
          padding: 0;
          .description-indicator {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            h1 {
              font-size: 20px !important;
            }
          }
        }
      }
      .table {
        width: 100%;
        .first {
          background-size: 150%;
        }
      }
    }

    .charts {
      display: flex;
      flex-flow: column wrap;
      max-width: 95vw;
      .item {
        margin-left: -10px;
      }
      .item:hover {
        @include boxWhiteShadow(0px, 0px, 10px, 1px);
      }
    }
  }
}
