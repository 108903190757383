@import "../src/shared/sass/essentials.styles.scss";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";
@import "https://cdn.bemaster.com/fonts/Gotham-Font/styles.css";
@import "../src/shared/sass/variables.scss";

// @import 'devextreme/dist/css/dx.common.css';
// @import 'devextreme/dist/css/dx.light.css';

body,
html {
  font-family: "Gotham", sans-serif !important;
  height: auto;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

//Post Full Working Autoadjust
html {
  overflow: hidden;
  @media (max-width: 576px) {
    overflow: auto !important;
  }
}

// Modal Styles
.purchase--modal {
  @media (max-width: $mobile) {
    border-radius: 10px !important;
    margin-bottom: 18% !important;
    margin-top: 50% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    .video-space-section {
      border-radius: 10px;
      height: 150px;
      margin-top: 20px;
      width: 100%;
    }
    .close-icon {
      font-size: 22px !important;
      margin-top: 49%;
      right: 22px;
    }
    .price-mini-region {
      align-items: baseline;
      flex-direction: column;
      width: 100%;
      .p-details {
        justify-content: flex-start !important;
        width: 100%;
      }
      .is-custom-button {
        display: flex;
        justify-content: center;
        margin-bottom: 15px !important;
        width: 100%;
      }
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.753) !important;
    z-index: 1010 !important;
    .ReactModal__Content--after-open {
      background-color: $grey-lighter !important;
      border-radius: 20px !important;
      bottom: 18% !important;
      top: 20% !important;
      left: 30% !important;
      //TODO: Change this shit
      // overflow: hidden !important;
      right: 30% !important;
    }
  }
}

div::-webkit-scrollbar,
ul::-webkit-scrollbar {
  width: 0.5em !important;
  height: 0.5em !important;
  transition: all 1s !important;
}
div::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track {
  background-color: #000 !important;
}
div::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 3px !important;
  > &:hover {
    background: rgba(255, 255, 255, 0.9) !important;
  }
}