@import "../../shared/sass/variables.scss";

.uploaders{
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  height: 100%;
  .uploader-container{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    height: 115px;
    h5{
      width: 100%;
      text-align: center;
      margin: 0;
    }
  }
}