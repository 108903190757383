@import "../../shared/sass/variables.scss";
// @import "~video-react/styles/scss/video-react";

.is-content-section {
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 0 !important;
  > .main-home-container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
    section {
      height: 100vh;
      scroll-snap-align: start;
      > h3 {
        font-family: "gotham", sans-serif;
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 3rem auto;
        filter: drop-shadow(2px 2px 4px #000);
      }
    }
    > #banner {
      .containerTittle {
        width: calc(100vw - 65px);
        height: calc(100vh - 95px);
        .containerText {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          h3 {
            font-family: "Gotham", sans-serif;
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 2px;
            filter: drop-shadow(2px 2px 6px #000);
            .Be {
              color: #b98e51;
              text-transform: uppercase;
              letter-spacing: 6px;
            }
            .Master {
              color: #be9b5f;
              text-transform: uppercase;
              letter-spacing: 6px;
            }
          }
        }
      }
      > .mouse_scroll {
        position: static;
        top: 100%;
        margin-left: calc(50vw - 50px);
        display: flex;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: flex-end;
        transform: translateY(-60px) translateX(-50px);
        transition: opacity 0.5s;
        > div {
          > .m_scroll_arrow {
            display: block;
            width: 5px;
            height: 5px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

            border-right: 2px solid white;
            border-bottom: 2px solid white;
            margin: 0 0 0px 0px;

            width: 16px;
            height: 16px;

            z-index: 999;
          }

          > .unu {
            margin-top: 1px;
          }

          > .unu,
          > .doi,
          > .trei {
            animation: mouse-scroll 1s infinite;
          }

          > .unu {
            -webkit-animation-delay: 0.1s;
            -webkit-animation-direction: alternate;

            animation-direction: alternate;
            animation-delay: alternate;
          }

          > .doi {
            -webkit-animation-delay: 0.2s;
            -webkit-animation-direction: alternate;

            animation-delay: 0.2s;
            animation-direction: alternate;

            margin-top: -6px;
          }

          > .trei {
            -webkit-animation-delay: 0.3s;
            -moz-animation-delay: 0.3s;
            -webkit-animation-direction: alternate;

            animation-delay: 0.3s;
            animation-direction: alternate;

            margin-top: -6px;
          }

          @keyframes mouse-scroll {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 0.5;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }
    > #notices {
      .notices-main {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 10vh;
        > .notice-container {
          background-color: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(10px);
          border: solid 20px;
          border-image: url("https://media.istockphoto.com/photos/laminate-wooden-floor-texture-background-picture-id1083302826?k=20&m=1083302826&s=170667a&w=0&h=bSRz2bpnwImMIWa1qPOw7pRIW4EWd_j1zE3zHrdoDtc=")
            160 160 round;
          color: #fff;
          font-family: arial, sans-serif;
          font-size: 100%;
          height: 55vh;
          position: relative;
          width: 55%;
          > ul {
            display: flex;
            flex-flow: row wrap;
            overflow: auto;
            padding: 2em;
            max-height: 100%;
            min-height: 100%;
            > div:nth-child(even) a {
              transform: rotate(4deg);
              position: relative;
              top: 5px;
              background-color: #273e55;
              color: #e6e6e6;
            }
            > div:nth-child(3n) a {
              transform: rotate(-3deg);
              transform: rotate(-3deg);
              position: relative;
              top: -5px;
              background-color: #e6e6e6;
              color: #000;
            }
            > div:nth-child(5n) a {
              transform: rotate(5deg);
              position: relative;
              top: -10px;
            }
            > div li a:hover {
              box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
              transform: scale(1.7);
              position: relative;
              z-index: 5;
            }
          }
          > .new-button {
            bottom: 0;
            cursor: pointer;
            align-items: center;
            backdrop-filter: blur(5px);
            border-radius: 100%;
            display: flex;
            font-size: 30px;
            height: 50px;
            justify-content: center;
            position: absolute;
            right: 0;
            transition: 0.3s ease filter;
            user-select: none;
            width: 50px;
            &:hover {
              filter: drop-shadow(0px 0px 1px #e6e6e6);
            }
            &:active {
              transform: scale(0.5);
            }
          }
        }
        > .companyContainer {
          width: 30%;
          height: 55vh;
          border-radius: 25px;
          overflow: hidden;
          background-color: #181818;
        }
      }
    }
    > #tutorials {
      > .wrapperTutorials {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
        justify-content: center;
        align-items: center;
      }
    }
  }
}
