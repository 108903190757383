@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.main-user-certification-container {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: calc(100vw - 65px);
  overflow-x: hidden;

  .banner {
    min-width: calc(100vw - 65px);
    max-width: calc(100vw - 65px);
    min-height: 40vh;
    max-height: 40vh;
    .banner-overlay {
      height: 100%;
      background: url("https://cdn.bemaster.com/media/images/admin/backgrounds/BannerPAD_certificados.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .banner-img {
        display: grid;
        place-items: center;
        min-height: 40vh;
        max-height: 40vh;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        h1 {
          filter: drop-shadow(2px 4px 6px #000);
          letter-spacing: 2px;
          .be {
            color: #b98e51; //Dorado Oscuro;
          }
          .masterText {
            color: #be9b5f; //Dorado Normal;
          }
        }
      }
    }
  }

  .certificateType {
    // padding-top: 2em;
    width: 80%;
    margin: 4em auto;
    h5 {
      width: 100%;
      margin: 50px auto;
      text-align: center;
      letter-spacing: 2px;
      font-size: 25px;
      filter: drop-shadow(2px 4px 2px #000);
    }
    #certificateFilter {
      width: 300px;
      margin: auto;
    }
  }
}
.downloadButtonContainer {
  width: 70%;
  margin: 0 auto -5em auto;
  display: flex;
  flex-flow: row-reverse;
  .downloadButton {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    height: 35px;
    width: 35px;
    margin: 15px;
    transition: ease-in-out 0.3s;
    filter: drop-shadow(4px 4px 4px #000);
    &:active {
      transform: scale(0.8);
    }
    .iconButton {
      color: #be9b5f;
      height: 100%;
      width: 100%;
      transition: ease-in-out 0.2s;
      &:active {
        color: #fff;
        filter: drop-shadow(2px 2px 2px #fff);
      }
    }
  }
}

.certificateContainer {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  color: #000;
  width: 70%;
  margin: 5em auto 15em auto;
  .certificateContain {
    .letterContainer {
      padding: 3rem 10%;
      .header {
        img {
          height: 50px;
          width: 50px;
        }
      }
    }
  }
}
