@import '../../../sass/essentials.styles.scss';
@import '../../../../components/card/card.styles.scss';
@import '../../../../shared/sass/variables.scss';

.is-custom-button {
    border-radius: 8px;
    margin-bottom: 10px;
    
    .button {
        border-radius: 8px;
        font-weight: bold;
        height: 32px;
        width: 250px;
    }
    
    .icon-section {
        display: flex;
        font-size: 22px;
    }
}

.hover:hover {
    @include elementHover(1, 0.2s, false);
    @include boxShadow(2px, 8px, 13px, 2px);
}
