@import '../../shared/sass/variables.scss';

.is-nav-section {
    align-items: center;
    color: white !important;
    display: flex;
    font-size: 14px;
    min-height: 96px;
    padding: 0px 0px 0 65px !important;
    width: -webkit-fill-available;
    letter-spacing: 1px;
    background: transparent !important;

    @media (min-width: 576px) {
        padding: 0 5% !important;
    }

    @media (max-width: $mobile) {
        justify-content: center;
        padding: 0 0 0 0 !important;
    }
    
    @media (max-width: 320px) {
        justify-content: unset !important;
        margin-left: 0 !important;
    }

    @media (max-width: 375px) {
        justify-content: unset;
        margin-left: 25px;
        padding: 0 !important;
    }

    .is-arrow-icon {
        @media (min-width: $mobile) {
            display: none !important;
        }
    }

    .navbar-content {
        .dark {
            color: $dark-gray;
        }
    }

    >.is-brand-section {
        display: flex;
        width: 160px;
    }
    >.is-shopping-brand-section {
        width: 120px !important;
    }
    .navbar-end {
        align-items: center;
        >.notification-parent, .settings-parent {
            height: 35px;
        }
        >.my-balance {
            align-items: center;
            color: $brown;
            display: flex;
        }
    }
}

.shopping-nav-margins {
    @media (max-width: $mobile) {
        justify-content: unset !important;
    }
}
