@charset "utf-8";

// Custom project variables
@import "./variables.scss";

@import "./modals.styles.scss";

// Mixins
@mixin center() {
    align-items: center;
    display: center;
    justify-content: center;
}

@mixin boxShadow($pX, $pY, $spread, $shadowSize, $darkScale: 0.72) {
    $sColor: rgba(0, 0, 0, $darkScale);
    box-shadow: $pX $pY $spread $shadowSize $sColor;
    -webkit-box-shadow: $pX $pY $spread $shadowSize $sColor;
    -moz-box-shadow: $pX $pY $spread $shadowSize $sColor;
}

@mixin boxWhiteShadow($pX, $pY, $spread, $shadowSize, $sColor: #ffffff61) {
    // $sColor: rgba(255, 255, 255, $whiteScale);
    box-shadow: $pX $pY $spread $shadowSize $sColor;
    -webkit-box-shadow: $pX $pY $spread $shadowSize $sColor;
    -moz-box-shadow: $pX $pY $spread $shadowSize $sColor;
}

// Basic Modal In Navbar Components
@mixin navbar-basic-container($mr:-10px, $width:265px, $minHeight: 180px) {
    align-items: center;
    border-radius: 12px;
    background-color: #2b2b2b;
    flex-direction: column;
    margin-top: 35px;
    margin-right: $mr;
    min-height: $minHeight;
    max-height: 230px;
    transition: all linear 0.25s;
    position: absolute;
    width: $width;
    z-index: 101;
}

// layout - background image
@mixin has-background-image($url-image) {
    background-image: url($url-image) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

// Custom background layout
@mixin has-custom-background($color: $gray-lighter) {
    background-color: $color;
}

// IMPORTANT: ALL VARIABLES HAS TO BE CUSTOMIZED BEFORE IMPORTIGN BULMA

// Bulma Import
@import "../../../node_modules/bulma/bulma.sass";
@import "../../../node_modules/bulma/sass/components/dropdown.sass";

// Pro-Side-Bar Imports
// @import '../../../node_modules/react-pro-sidebar/dist/scss/styles.scss';

//Personalized Icons
@import './icons.styles.scss';