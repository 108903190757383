@import '../../shared/sass/variables.scss';

.affiliations-manager-main-container {

    .charts {
        margin-top: 60px !important;
    }
    .chart-demo {
        margin-top: 60px !important;
    }   
    .options {
        background-color: $brown;
        >.option {
            align-items: center;
            display: flex;
        }
    }

    .dx-row .dx-data-row .dx-column-lines {
        background-color: #C19656;
    }
    .ScrollbarsCustom-TrackY {
        height: calc(100% - 110px) !important;
        top: 110px !important;
        background-color: transparent !important;
        .ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb {
            background-color: transparent !important;
        }
    }
    .ScrollbarsCustom-TrackX {
        background-color: transparent !important;
        .ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb {
            background-color: transparent !important;
        }
    }
    >.dx-button {
        width: 100%;
    }
}